import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/appStore';
import { BareVariant, ExtendedProduct, Variant } from 'types/product';
import { APP_HYDRATE } from 'redux/actions';
import { findVariantImageIndex } from 'lib/product/findVariantImageIndex';
import { castToVariantOrNull } from 'lib/products/castToVariantOrNull';

export interface ProductsState {
  product: ExtendedProduct | null;
  selectedVariant: BareVariant | null;
  selectedImageIndex: number;
}

const initialState: ProductsState = {
  product: null,
  selectedVariant: null,
  selectedImageIndex: 0,
};

export const productPageSlice = createSlice({
  name: 'productPage',
  initialState,
  reducers: {
    setProductAndVariant: (state, { payload }: PayloadAction<ExtendedProduct>) => {
      state.product = payload;
      state.selectedVariant = payload.defaultVariant;
      state.selectedImageIndex = findVariantImageIndex(
        payload,
        castToVariantOrNull(payload.defaultVariant)
      );
    },
    setSelectedVariant: (state, { payload }: PayloadAction<Variant | null>) => {
      state.selectedVariant = payload;
      state.selectedImageIndex = findVariantImageIndex(state.product, payload);
    },
    setSelectedImageIndex: (state, { payload }: PayloadAction<number>) => {
      state.selectedImageIndex = payload;
    },
    resetProductPage: (state) => {
      state.product = initialState.product;
      state.selectedVariant = initialState.selectedVariant;
      state.selectedImageIndex = initialState.selectedImageIndex;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(APP_HYDRATE, (clientState, { payload }) => {
      if (payload.productPage.product === null) return;
      return {
        ...clientState,
        ...payload.productPage,
      };
    });
  },
});

export const {
  setProductAndVariant: setProductAndVariant,
  setSelectedVariant: setProductPageVariant,
  setSelectedImageIndex,
  resetProductPage,
} = productPageSlice.actions;

const slice = (state: RootState) => state.productPage;

export const getProductPageProduct = createSelector(slice, (s) => s.product);
export const getProductPageVariant = createSelector(slice, (s) => s.selectedVariant);
export const selectProductOrVariantSellable = createSelector(
  getProductPageProduct,
  getProductPageVariant,
  (product, variant) => variant?.isSellable || product?.sellable
);
export const selectProductAddresses = createSelector(
  getProductPageProduct,
  (product) => product?.addresses || []
);
export const selectShowReservationFlowAndQuantity = createSelector(
  getProductPageProduct,
  getProductPageVariant,
  (product, variant) => !product?.isAffiliateDeal && variant?.isSellable
);
export const selectShowProductVariantSelect = createSelector(
  getProductPageProduct,
  (product) => product && !product.isAffiliateDeal && product.variants.length > 1
);
export const selectShowLowStockAlert = createSelector(
  getProductPageProduct,
  getProductPageVariant,
  (product, variant) => variant?.hasLowStock && variant?.isSellable && product?.isAffiliateDeal
);

export const selectShowDeliveryEstimate = createSelector(
  getProductPageProduct,
  getProductPageVariant,
  (product, variant) => {
    const isSellable = variant?.isSellable || product?.sellable;

    if (!isSellable) return false;

    const castVariant = castToVariantOrNull(variant);

    return castVariant?.isPhysical || false;
  }
);

export const getSelectedImageIndex = createSelector(slice, (s) => s.selectedImageIndex);

export default productPageSlice.reducer;
