import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Product } from 'types/product';
import { RootState } from 'redux/appStore';
import { Loading } from 'constants/common';
import { PAGINATION_REQUEST_DEFAULTS } from 'constants/pagination';
import { APP_HYDRATE } from 'redux/actions';

export interface ProductsState {
  data: Product[];
  loading: Loading;
  isEndReached: boolean;
  pagination: {
    page: number;
    itemsPerPage: number;
  };
}

const initialState: ProductsState = {
  data: [],
  loading: Loading.IDLE,
  pagination: PAGINATION_REQUEST_DEFAULTS,
  isEndReached: false,
};

const removeDuplicates = (currentProducts: Product[], newProducts: Product[]): Product[] => {
  const currentProductIds: Set<number> = new Set();
  currentProducts?.map((p) => currentProductIds.add(p.id));

  const uniqueNewProducts: Product[] = [];

  newProducts.forEach((product) => {
    if (!currentProductIds.has(product.id)) uniqueNewProducts.push(product);
  });

  return uniqueNewProducts;
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, { payload }: PayloadAction<Product[]>) => {
      state.data = payload;
    },
    productsPageLoaded: (state, { payload }: PayloadAction<Product[]>) => {
      const payloadWithoutDuplicates = removeDuplicates(state.data, payload);
      state.data = [...state.data, ...payloadWithoutDuplicates];
      state.loading = Loading.SUCCEEDED;

      if (payload.length < state.pagination.itemsPerPage) {
        state.isEndReached = true;
      }
    },
    setPaginationState: (state, { payload }) => {
      state.pagination = payload;
    },
    setProductLoadingStatus: (state, { payload }: PayloadAction<Loading>) => {
      state.loading = payload;
    },
    resetProductsState: (state) => {
      state.data = [];
      state.loading = Loading.IDLE;
      state.pagination = PAGINATION_REQUEST_DEFAULTS;
      state.isEndReached = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(APP_HYDRATE, (clientState, { payload }) => {
      return {
        ...clientState,
        ...payload.products,
      };
    });
  },
});

export const {
  setProducts,
  productsPageLoaded,
  setPaginationState,
  setProductLoadingStatus,
  resetProductsState,
} = productsSlice.actions;

export const selectProductSlice = (state: RootState) => state.products;
export const selectProducts = createSelector(selectProductSlice, (s) => s.data);
export const selectProductsPagination = createSelector(selectProductSlice, (s) => s.pagination);
export const selectProductsEndReached = createSelector(selectProductSlice, (s) => s.isEndReached);
export const selectProductsIsLoading = createSelector(
  selectProductSlice,
  (s) => s.loading === Loading.PENDING
);
export const selectProductsIsLoadingFailed = createSelector(
  selectProductSlice,
  (s) => s.loading === Loading.FAILED
);
export const selectIsProductsListEmpty = createSelector(
  selectProductSlice,
  (s) => !s.data.length && s.loading === Loading.SUCCEEDED
);

export default productsSlice.reducer;
