export function getEuros(price?: number | null) {
  if (price === undefined || price === null || price === 0) {
    return '0';
  }

  return Math.floor(Math.abs(price) / 100);
}

export function getCents(price?: number | null) {
  if (price === null || price === undefined) {
    return '';
  }

  if (price === 0) {
    return '00';
  }

  let stringPrice = price.toString();
  return stringPrice.slice(stringPrice.length - 2).padStart(2, '0');
}

export function formatPrice(price: number | string, options = { removeWhiteSpace: false }): string {
  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  });

  let formatted = formatter.format((price as number) / 100);

  if (options.removeWhiteSpace) {
    formatted = formatted.replace(/\s/g, '');
  }

  return formatted;
}

export function calcSavingPercentage(
  originalPrice: number | null,
  price: number | null
): string | null {
  if (
    originalPrice === null ||
    price === null ||
    isNaN(originalPrice) ||
    isNaN(price) ||
    originalPrice === 0
  ) {
    return null;
  }

  const difference = originalPrice - price;
  const saved = (difference / originalPrice) * 100;

  if (saved <= 0) return null;
  return Math.round(saved).toString();
}
