import { QUANTITY_LABEL } from 'constants/enums';
import { PRODUCT_MINIMUM_CART_QUANTITY_DEFAULT, STOCK_ALERT_THRESHOLD } from 'constants/product';
import { trimSecondsFromTime } from 'lib/date';
import { omitUndefinedRecursively, removeUndefined } from 'lib/object';
import config from 'next/config';
import {
  BareVariant,
  ExtendedProduct,
  Product,
  ProductAddress,
  ProductDescriptionSection,
  ReservationAvailability,
  Variant,
} from 'types/product';
import { createTaxonHref } from 'lib/taxon/taxonUtils';
import { BareTaxon } from 'types/taxons';

const { publicRuntimeConfig } = config();

const transformVariantJson = (variant: any): Variant => {
  return {
    id: variant.id,
    name: variant.name,
    code: variant.code,
    price: variant.price,
    originalPrice: variant.originalPrice,
    sku: variant.sku,
    isSellable: variant.isSellable ?? true,
    isReservationDeal: variant.isReservationDeal ?? false,
    isMergedVouchersDeal: variant.isMergedVouchersDeal ?? false,
    isCallOnlyDeal: variant.isCallOnlyDeal ?? false,
    quantityLabel: variant.quantityLabel,
    hasLowStock: variant.availableStock < STOCK_ALERT_THRESHOLD,
    isPhysical: variant.isPhysicalProduct ?? variant.isPhysical ?? false,
  };
};

function getTransformedDefaultVariant(productJson: any): Variant | BareVariant {
  return productJson.defaultVariant
    ? transformVariantJson(productJson.defaultVariant)
    : createProductPreviewVariant(productJson);
}

// In productPreview configuration, the product might not have any variants attached. In those cases, we create this dummy.
function createProductPreviewVariant(productJson: any): BareVariant {
  return {
    price: 1,
    originalPrice: 2,
    name: productJson.name || '',
    isSellable: false,
    hasLowStock: true,
    quantityLabel: QUANTITY_LABEL.PRODUCT,
  };
}

export const transformToBareTaxon = (taxonJson: any): BareTaxon => {
  return {
    id: taxonJson.id,
    name: taxonJson.name,
    tradeTrackerCategory: taxonJson.tradeTrackerCategory,
    parent: (taxonJson.parent && transformToBareTaxon(taxonJson.parent)) || null,
    slug: taxonJson.slug,
    hasLocationSupport: taxonJson.hasLocationSupport || false,
    code: taxonJson.code,
    href: createTaxonHref(taxonJson.slug),
    icon: taxonJson.icon || null,
  };
};

export const transformProductJson = (productJson: any): Product => {
  if (!productJson || productJson.error) {
    console.error({ error: productJson?.error });
    throw new Error();
  }

  const translations = {
    slug: productJson.slug,
    href: productJson.slug,
    name: productJson.name,
    description: productJson.description,
    shortDescription: productJson.shortDescription,
    metaDescription: productJson.metaDescription,
    metaKeywords: productJson.metaKeywords,
  };

  const mainImage = productJson.images.length > 0 ? productJson.images[0] : null;

  const variants = productJson.variants
    ? productJson.variants
        .map((variant: any) => transformVariantJson(variant))
        .sort((a: any, b: any) => Number(b.isSellable) - Number(a.isSellable))
    : [];
  const defaultVariant = getTransformedDefaultVariant(productJson);

  const product: Product = {
    ...productJson,
    tag: productJson.tag
      ? {
          text: productJson.tag.text,
          backgroundColor: productJson.tag.backgroundColor || '#F1EBFF',
          fontColor: productJson.tag.fontColor || '#000000',
        }
      : null,
    id: productJson.id,
    code: productJson.code,
    slug: translations.slug,
    href: '/aanbieding/' + translations.slug,
    imageSrc: !!mainImage ? publicRuntimeConfig.imageUrl + '/media/image/' + mainImage.path : null,
    imageAlt: mainImage?.altText ?? translations.name,
    name: translations.name,
    images: productJson.images.map((img: any) => ({
      src: publicRuntimeConfig.imageUrl + '/media/image/' + img.path,
      alt: img.altText ?? translations.name,
      variants: Array.isArray(img.variants) ? img.variants : [],
    })),
    defaultVariant,
    variants,
    sku: productJson?.sku,
    sellable: productJson.variants
      ? productJson.variants.filter((variant: any) => variant.isSellable).length > 0
      : false,
    customerBuyingLimit: productJson.customerBuyingLimit,
    minimumCartQuantity: productJson.minimumCartQuantity || PRODUCT_MINIMUM_CART_QUANTITY_DEFAULT,
    recommendationId: productJson.recommendationId || null,
    isAffiliateDeal: productJson.affiliateConfig !== null,
    affiliateConfig: productJson.affiliateConfig,
    addresses: transformProductAddressesJson(productJson.addresses, productJson.id),
    mainTaxon: transformToBareTaxon(productJson.mainTaxon),
    icon: productJson.icon.name || null,
    hasExternalBooking: productJson.hasExternalBooking || false,
    isWhiteLabel: productJson.isPurchasingWithCouponEnforced || false,
  };

  omitUndefinedRecursively(product);

  return product;
};

export const transformExtendedProductJson = (productJson: any): ExtendedProduct | null => {
  if (!productJson || productJson.error) {
    console.error({ error: productJson?.error });
    return null;
  }

  const translations = {
    description: productJson.description,
    shortDescription: productJson.shortDescription,
    metaDescription: productJson.metaDescription,
    metaKeywords: productJson.metaKeywords,
  };

  const descriptions = productJson.descriptions.length
    ? productJson.descriptions.filter(
        (description: ProductDescriptionSection) => description.title.length && description.body
      )
    : [];

  return {
    descriptions,
    shortDescription: translations.shortDescription,
    metaDescription: translations.metaDescription,
    metaKeywords: translations.metaKeywords,
    ...transformProductJson(productJson)!,
    isAffiliateDeal: productJson.affiliateConfig !== null,
    affiliateConfig: productJson.affiliateConfig,
    metaTitle: productJson.metaTitle || null,
    icon: productJson.icon.name || null,
  };
};

export function transformProductAddressesJson(json: any, productId: number): ProductAddress[] {
  if (!json?.length || json.error) return [];

  return json.map(
    ({ coordinates, ...address }: any) =>
      ({
        ...address,
        productId,
        latitude: coordinates?.latitude || null,
        longitude: coordinates?.longitude || null,
      } as ProductAddress)
  );
}

export function transformReservationAvailability(json: any): ReservationAvailability {
  if (!json?.date) throw new Error('Invalid reservation response');

  return {
    date: json.date,
    timeslots: json?.timeslots ? json.timeslots.map(trimSecondsFromTime) : undefined,
  };
}

export function transformReservationAvailabilities(json: any): ReservationAvailability[] {
  if (!json?.length || json.error) return [];

  return json.map(transformReservationAvailability).map(removeUndefined); // remove timeslots if undefined
}
