import {
  Banner,
  BareProduct,
  Customer,
  OrderSummary,
  Product,
  SortingSelection,
  Taxon,
  Variant,
} from 'types/types';
import { tracker } from '../index';
import ProductClickEventFactory from '../tags/productClick/ProductClickEventFactory';
import { TrackingProductList } from 'types/tracking';
import ErrorEventFactory from '../tags/error/ErrorEventFactory';
import TaxonEventFactory from '../tags/taxon/TaxonEventFactory';
import { Item } from 'types/ui';
import SortingSelectorEventFactory from 'modules/tracking/tags/SortingSelector/SortingSelectorEventFactory';
import BannerEventFactory from 'modules/tracking/tags/banner/BannerEventFactory';
import OrderPurchasedTag from 'modules/tracking/tags/order/OrderPurchasedTag';
import SovendusBannerEventTag from 'modules/tracking/tags/SovendusBannerEventTag';
import CartReminderClickEventFactory from 'modules/tracking/tags/cartReminder/CartReminderClickEventFactory';
import JumboPromoSubmitClickEventFactory from 'modules/tracking/tags/jumboPromo/JumboPromoSubmitClickEventFactory';
import { NavigationOtherTag } from 'modules/tracking/tags/NavigationOtherTag';
import {
  LocationEventActions,
  LocationSelectionEventTag,
} from 'modules/tracking/tags/LocationSelectionEventTag';

export async function productClick({
  product,
  productList,
  productPosition,
}: {
  product: Product;
  productList?: TrackingProductList;
  productPosition?: number;
}) {
  const tag = ProductClickEventFactory.productClick({
    product,
    productList,
    productPosition,
  });

  await tracker.publish(tag);
}

export async function trackProductImpressions({
  products,
  productList,
  productIndexStart,
}: {
  products: Array<Product>;
  productList?: TrackingProductList;
  productIndexStart: number;
}) {
  const chunkSize = 6;

  for (let i = 0; i < products.length; i += chunkSize) {
    const chunk = products.slice(i, i + chunkSize);

    if (chunk.length === 0) {
      return;
    }

    const tag = ProductClickEventFactory.productImpressions({
      products: chunk,
      productList,
      productIndexStart: productIndexStart + i,
    });

    await tracker.publish(tag);
  }
}

export async function addToCartClick({
  product,
  variant,
  productList,
  productPosition,
  quantity,
}: {
  product: BareProduct;
  variant: Variant;
  productList: TrackingProductList | null;
  productPosition: number;
  quantity: number;
}) {
  const tag = ProductClickEventFactory.addToCartClick({
    product,
    productList,
    quantity,
    productPosition,
    variant,
  });
  await tracker.publish(tag);
}

export async function preAddToCartClick({
  product,
  productList,
  productPosition,
}: {
  product: Product;
  productList?: TrackingProductList;
  productPosition: number;
}) {
  const tag = ProductClickEventFactory.preAddToCartClick({ product, productList, productPosition });

  await tracker.publish(tag);
}

export async function trackRemoveFromCartClick({
  product,
  variant,
  productList,
  productPosition,
  quantity,
}: {
  product: BareProduct;
  variant: Variant;
  productList?: TrackingProductList;
  productPosition: number;
  quantity: number;
}) {
  const tag = ProductClickEventFactory.removeFromCartClick({
    product,
    productList,
    quantity,
    productPosition,
    variant,
  });
  await tracker.publish(tag);
}

export async function trackUserLogin({ customer }: { customer: Customer }) {
  const tag = ProductClickEventFactory.trackUserLogin({ customer });
  await tracker.publish(tag);
}

export async function trackUserRegister({ customer }: { customer: Customer }) {
  const tag = ProductClickEventFactory.trackUserRegister({
    customer,
  });
  await tracker.publish(tag);
}

export async function trackUserDetailsChange({ customer }: { customer: Customer }) {
  const tag = ProductClickEventFactory.trackUserDetailsChange({
    customer,
  });
  await tracker.publish(tag);
}

export async function trackNewsletterSubscribe(location: string) {
  const tag = ProductClickEventFactory.newsletterSubscribe(location);
  await tracker.publish(tag);
}

export async function taxonClick({ taxon }: { taxon: Taxon }) {
  const tag = TaxonEventFactory.taxonClick({
    taxonName: taxon.name,
  });

  await tracker.publish(tag);
}

export async function trackSortingSelection(selectedItem: Item<SortingSelection>) {
  await tracker.publish(SortingSelectorEventFactory.selectSorting(selectedItem.label));
}

export async function trackChildTaxonClick({
  mainTaxonName,
  subTaxonName,
}: {
  mainTaxonName: string;
  subTaxonName: string;
}) {
  const tag = TaxonEventFactory.subTaxonClick({
    mainTaxonName,
    subTaxonName,
  });

  await tracker.publish(tag);
}

export async function trackBannerClick({
  banner,
  width,
  height,
  position,
}: {
  banner: Banner;
  width: string;
  height: string;
  position: number;
}) {
  await tracker.publish(
    BannerEventFactory.bannerClick({
      id: banner.id.toString(),
      promoName: banner.code.toLowerCase(),
      creative: `${width}x${height}`,
      position: position.toString(),
    })
  );
}

export async function trackSovendusBannerClick({ position }: { position: string }) {
  await tracker.publish(new SovendusBannerEventTag({ position }));
}

export async function trackLoginError() {
  const tag = ErrorEventFactory.trackLoginError();

  await tracker.publish(tag);
}

export async function affiliateProductClick({ buttonText }: { buttonText: string }) {
  const tag = ProductClickEventFactory.affiliateProductClick({
    buttonText: buttonText,
  });

  await tracker.publish(tag);
}

export async function trackOrderPurchasedEvent({ order }: { order: OrderSummary }) {
  const tag = new OrderPurchasedTag(order);

  await tracker.publish(tag);
}

export async function trackCartReminderToOverviewClick() {
  await tracker.publish(CartReminderClickEventFactory.toCartOverviewClick());
}

export async function trackJumboPromoSubmitClick({ promocode }: { promocode: string }) {
  await tracker.publish(JumboPromoSubmitClickEventFactory.jumboPromoSubmitClick({ promocode }));
}

export async function trackLocationSelection({
  location,
  eventAction,
}: {
  location: string;
  eventAction: LocationEventActions;
}) {
  await tracker.publish(new LocationSelectionEventTag({ location, eventAction }));
}

export async function trackNavigationToMaps() {
  await tracker.publish(
    new NavigationOtherTag({ eventAction: NavigationOtherTag.EVENT_ACTION_GO_TO_MAPS })
  );
}

export async function trackNavigationToList() {
  await tracker.publish(
    new NavigationOtherTag({ eventAction: NavigationOtherTag.EVENT_ACTION_GO_TO_LIST })
  );
}
